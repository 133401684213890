.tasks-widget {
    background-color: #f8f8f8;
    border-radius: 4px;
    padding: 1em;
    box-shadow: 1px 1px 5px #8d8c9b;
}

.tasks-widget p {
    line-height: 1.3;
    margin-bottom: 0.1em;
}

.tasks-widget-item {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.1em;
    padding: 0.3em 0;
    align-items: center;
    min-height: 48px;
}