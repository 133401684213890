.modal {
  background: rgba(0, 0, 0, 0.5);
  display: none;


  &.show {
    display: flex;
  }

  &.modal_img {
    padding: 15px;
    justify-content: center;
    align-items: center;

    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__close {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
    cursor: pointer;
    font-size: 2rem;
    color: #fff;
    line-height: 10px;
    height: 25px;
    width: 25px;
    text-align: center;
  }
}