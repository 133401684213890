@keyframes dot_load {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}

.load {
  display: inline-block;
  min-width: 6px;
  float: none;
  text-align: left;

  &::after {
    content: '';
    animation: dot_load 1s 0s linear infinite;
  }
}
