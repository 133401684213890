
.vertical-align-unset {
  vertical-align: unset !important;
}

table.vertical-align-unset {
  & td,
  & th {
    vertical-align: unset !important;
  }
}
.vertical-align-middle {
  vertical-align: middle !important;
}

table.vertical-align-middle {
  & td,
  & th {
    vertical-align: middle !important;
  }
}