.video-list {
  width: 100%;
  max-height: 100%;
  height: 100%;

  &__head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &__name {
    font-size: 1.1rem;
    margin: 10px 0 0 15px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-bottom: 10px;
      width: auto;
      max-width: 100%;
      overflow: hidden;
    }

    &.cur,
    &:hover {
      color: #2c77f4;
      transition: color 0.3s ease;
    }
  }

  &__video-container {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    & .video-list {
      &__video-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      &__comments-wrapper {
        display: none;
      }


      &__video {
        height: 290px;
        max-width: 100%;
        max-height: 100%;
        margin: 10px 0 0;
        cursor: pointer;
        background: #000;
      }
    }
  }

  &__error {
    width: 100%;
    display: flex;
    padding: 25px 10px;
    background: #0f0f16;
    color: #fff;
    align-items: center;
    justify-content: center;
    text-align: center;

    & > span {
      font-size: 3rem;
      padding-right: 10px;
    }
  }

  &__btn {
    display: none;
  }

  &__body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    max-height: 100%;
    height: 100%;

    &.video-list__body--full {
      position: fixed;
      width: 100vw;
      height: 100vh;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 10000;

      & .video-list {

        &__video-container {
          max-width: calc(100% - 60px);
          max-height: calc(100% - 60px);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: row;
          position: relative;

          & .video-list {
            &__video-wrapper {
              width: calc(100% - 320px);
              height: 100%;
            }

            &__video-holst {
              display: flex;
              justify-content: center;
              position: absolute;
              left: 0;
              top: 0;
              right: 320px;
              bottom: 30px;

              &--play {
                pointer-events: none;
              }
            }

            &__video-arrow {
              position: absolute;
              display: block;
              width: 100px;
              height: 100px;
              pointer-events: none;

              & > svg {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
              }

              &--delete {
                & > svg {
                  & {
                    line {
                      pointer-events: all;
                      cursor: pointer;
                    }
                  }
                }
              }
            }

            &__comments-wrapper {
              width: 320px;
              display: flex;
              flex-direction: column;
            }

            &__comments-list {
              height: 100%;
              padding: 10px;
              display: flex;
              flex-direction: column-reverse;
              overflow-y: auto;
            }

            &__comment {
              border: 1px solid $border-color;
              padding: 5px;
              border-radius: 5px;
              margin-left: 30px;
              margin-top: 10px;
            }

            &__time {
              padding: 1px 5px;
              border-radius: 4px;
              border: 1px solid $border-color;
              background-color: $gray-100;
              cursor: pointer;
              display: block;
              margin-left: auto;
              font-size: 0.95rem;

              &:hover {
                background-color: $gray-200;
              }
            }

            &__comments-input {
              border-top: 1px solid $border-color;
              padding: 10px;
              display: flex;
              box-shadow: $box-shadow;
            }

            &__video {
              cursor: default;
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-height: 100%;
              margin: 0;

            }
          }

          &--no-comments {
            & .video-list {
              &__video-wrapper {
                width: 100%;
              }

              &__video-holst,
              &__video-comments {
                display: none;
              }
            }
          }
        }

        &__btn {
          display: block;
          position: absolute;
          width: 30px;
          height: 50px;
          font-size: 5rem;
          line-height: 51px;
          color: #fff;
          top: calc(50% - 25px);
          bottom: calc(50% - 25px);
          text-align: center;
          cursor: pointer;
          transition: 0.1s ease all;
          user-select: none;

          &:hover {
            background: rgba(0, 0, 0, 0.5);
          }

          &__prev {
            left: 0;
            padding-left: 5px;
          }

          &__next {
            right: 0;
            padding-right: 5px;
          }

          &__close {
            width: 25px;
            height: 25px;
            top: 5px;
            right: 5px;
            font-size: 2rem;
            line-height: 24px;

            &:hover {
              background: transparent;
            }
          }
        }
      }
    }
  }
}