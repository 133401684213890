.img-list {
  width: 100%;
  max-height: 100%;
  height: 100%;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;

  &--solo {
    & .slick-slider {
      & .slick-track {
        display: flex;
        justify-content: center;
      }

      & .slick-slide {

        & > div {

          & > div {
            padding: 0;
          }
        }
      }
    }
  }

  & .slick-slider {
    & .slick-slide {
      max-height: 320px;
      height: 320px;
      display: block;

      & > div {
        height: 100%;
        max-height: 100%;

        & > div {
          height: 100%;
          padding: 0 10px;
          position: relative;

          &:focus {
            outline: none;
          }

          & > a {
            width: 100%;
          }

          & > a > img {
            height: 100%;
            margin: 0;
            border-radius: 0;
          }

          & > p {
            position: absolute;
            padding: 15px 25px;
            margin: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;

            & > span {
              padding: 4px 8px;
              border-radius: 5px;
              max-width: 100%;
              background: rgba(15, 15, 22, 0.85);
              color: #fff;
              font-weight: 500;
              text-align: center;
              opacity: 0;
              transition: 0.1s ease all;
              transform: translateY(15px);
              user-select: all;
            }

            &:hover {
              & > span {
                opacity: 1;
                transform: translateY(0);
              }
            }
          }
        }
      }
    }

    & .slick-track {
      height: 320px;
      display: flex;
      //overflow: hidden; //temporary disabled
    }

    & .slick-arrow {
      &:before {
        color: #0f0f16;
      }

      &.slick {
        &-next {
          right: -22px;
        }

        &-prev {
          left: -22px;
        }
      }
    }
  }

  &__body {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    max-height: 100%;
    height: 100%;

    & .img-list {

      &__btn {
        display: none;
      }
    }

    &.img-list {
      &__body--full {
        position: fixed;
        width: 100vw;
        height: 100vh;
        padding: 25px;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 10000;

        & .img-list {

          &__btn {
            display: block;
            position: absolute;
            width: 30px;
            height: 50px;
            font-size: 5rem;
            line-height: 51px;
            color: #fff;
            top: calc(50% - 25px);
            bottom: calc(50% - 25px);
            text-align: center;
            cursor: pointer;
            transition: 0.1s ease all;
            user-select: none;

            &:hover {
              background: rgba(0, 0, 0, 0.5);
            }

            &__close {
              width: 25px;
              height: 25px;
              top: 1.5rem;
              right: 1.5rem;
              font-size: 2rem;
              line-height: 24px;
              z-index: 10000;

              &:hover {
                background: transparent;
              }
            }
          }
        }

        .slick-slider {
          height: 100%;

          & .slick-list {
            height: 100%;
          }

          & .slick-track {
            height: 100%;
          }

          & .slick-slide {
            height: 100%;
            max-height: 100%;

            & > div {

              & > div {
                display: inline-flex !important;
                justify-content: center;

                & > a {
                  max-width: 90%;
                }

                & > a > img {
                  object-fit: contain;
                  height: 100% !important;
                  width: auto !important;
                  max-width: 100% !important;
                  max-height: 100% !important;
                  margin: 0 auto;
                  border-radius: 0;
                }

                & > img {
                    object-fit: contain;
                    height: 100% !important;
                    width: auto !important;
                    max-width: 100% !important;
                    max-height: 100% !important;
                    margin: 0;
                    border-radius: 0;
                }

              }
            }
          }

          & .slick-arrow {
            &:before {
              color: #fff;
            }

            &.slick {
              &-next {
                right: 0;
                z-index: 10000;
              }

              &-prev {
                left: 0;
                z-index: 10000;
              }
            }
          }

          & .slick-dots {
            & li {

              & button {
                &:before {
                  opacity: 0.5;
                }

                &:hover {
                  &:before {
                    opacity: 0.75;
                  }
                }
              }

              &.slick-active button {
                &:before {
                  opacity: 1;
                }
              }

              & button,
              &.slick-active button {
                &:before {
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}
