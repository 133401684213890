.btn {
  &:disabled {
    cursor: default;
  }

  &.btn {
    &-label {
      &-secondary {
        cursor: default;
        background: #f4f5f8;
      }
    }
  }
}
.btn-delete-ref {
  position: absolute;
  right: 10px;
  left: 10px;
  background-color: rgb(253 57 122 / 70%);
  color: #FFFFFFFF;

  &:hover {
    background-color: rgb(253 57 122 / 100%);
  }
}
