.diagonal-cell {
  position: relative;
  overflow: hidden;

  & span {
    position: absolute;
    display: inline-block;
    max-width: 60%;
    width: 60%;

    &:nth-child(1) {
      left: 0.75rem;
      bottom: 3px;
      text-align: left;
    }

    &:nth-child(2) {
      right: 0.75rem;
      top: 2px;
      text-align: right;
    }
  }
}