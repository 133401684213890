.filters {
  height: 100%;
  max-height: 100%;
  position: relative;
  width: 270px;
  min-height: 40px;
  z-index: 1;

  & &__wrapper {
    width: 100%;
    position: absolute;
    height: 100%;
    padding: 5px;
    border-radius: 21px;
    transition: 0.1s linear all;
    background: transparent;
    border: 1px solid transparent;
  }

  & &__btn {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 0;
    max-height: 0;
    transition: 0.1s linear all;

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      height: 3px;
      max-height: 3px;
    }

    & > .btn {
      pointer-events: none;
      box-shadow: none;

      & > i {
        pointer-events: all;
      }

      width: 100%;
      margin-right: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 3px !important;
    }

    &:last-child {
      & > .btn {
        margin-bottom: 0 !important;
      }
    }
  }

  &.filters--full {
    & .filters {
      &__wrapper {
        height: auto;
        background: #fff;
        box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
        border: 1px solid #eff0f6;
      }

      &__btn {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 35px;
        max-height: 35px;

        &:last-child {
          height: 32px;
          max-height: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .filters {
    width: 200px;
  }
}