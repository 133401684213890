
*, a, span, div, p, {
  &.hover:hover,
  &.hover.active {
    color: #2c77f4 !important;
    transition: color 0.3s ease;
    cursor: pointer;
  }
}

