.datepickers-container {
  z-index: 10000000;

  & .datepicker {
    & .datepicker--time {
      padding-top: 14px;

      & .datepicker--time-sliders {
        display: flex;
        align-items: center;

        & .datepicker--time-row {
          margin: 0;

          &:nth-child(2) {
            display: none;
          }

          & input[type='range'] {
            &:focus {
              &::-webkit-slider-thumb {
                background: #2C77F3;
                border-color: #2C77F3;
              }

              &::-moz-range-thumb {
                background: #2C77F3;
                border-color: #2C77F3;
              }

              &::-ms-thumb {
                background: #2C77F3;
                border-color: #2C77F3;
              }
            }
          }
        }
      }
    }

    &--hidden-minutes {
      & .datepicker--time {
        //background: red;
        & .datepicker--time-sliders {
          display: flex;
          align-items: center;

          & .datepicker--time-row {
            margin: 0;

            &:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }

    &--day-name {
      color: #2C77F3;
      font-weight: bold;
    }

    &--cell {
      &.-current- {
        color: #2C77F3;
      }

      &.-focus- {
        background: #2C77F3;
        color: #fff;
      }

      &.-disabled-.-focus- {
        color: #aeaeae;
        background: transparent;
      }

      &.-disabled-.-focus-.-other-month- {
        color: #dedede;
        background: transparent;
      }

      &.-range-from-,
      &.-range-to-,
      &.-selected-,
      &.-other-month-.-focus-.-selected- {
        color: #fff;
        background: #2C77F3;
        border: 1px solid #2C77F3;
      }

      &.-in-range- {
        background: rgba(44, 119, 243, 0.1);
      }

      &.-in-range-.-focus- {
        background: rgba(44, 119, 243, 0.5);
      }

      &.-disabled- {
        &:hover {
          background: none;
        }
      }
    }
  }
}