//
// User Pics List
//


.kt-list-pics {
  > a {
    margin-right: 0.25rem;
    display: inline-block;

    > span > img,
    > img {
      object-fit: cover;
      width: 3.36rem;
      height: 3.36rem;
      border-radius: 50%;

      @include kt-rounded {
        border-radius: 3px;
      }
    }

    & > span {
      overflow: hidden;
      padding: 1px;
      border-radius: 50%;

      @include kt-rounded {
        border-radius: 5px;
      }
    }
  }

  &.kt-list-pics--circle {
    > a {
      > span > img,
      > img {
        object-fit: cover;
        width: 3.36rem;
        height: 3.36rem;
        border-radius: 50%;
      }
    }
  }

  &.kt-list-pics--sm {
    > a {
      > span > img,
      > img {
        object-fit: cover;
        width: 2.14rem;
        height: 2.14rem;
      }
    }
  }

  &.kt-list-pics-rounded--sm {
    > a {
      > span > img,
      > img {
        object-fit: cover;
        width: 2.14rem;
        height: 2.14rem;
      }
    }
  }
}