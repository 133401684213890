.kanban {
  min-width: 100%;
  height: calc(100vh - 189px);
  margin: -25px -25px -5px;
  background: #fff;
  width: calc(100% + 50px);
  overflow: auto;

  @media (max-width: 1024px) {
    height: calc(100vh - 138px);
  }

  &__wrapper {
    width: fit-content;
    min-width: 100%;
    min-height: 100%;
    padding: 0 25px 25px;
    display: flex;
    flex-direction: column;
  }

  &__header {
    position: sticky;
    top: 0;
    padding-top: 25px;
    padding-bottom: 15px;
    background: #fff;
    width: 100%;
    display: flex;
  }

  &__body {
    flex: 1 0 auto;
    display: flex;
  }

  & &__col {
    width: 240px;
    min-height: 100%;
    flex: 1 0 auto;
    margin: 0 5px;

    &-title {
      width: 100%;
      font-size: 1rem;
      text-align: center;
      margin-bottom: 0;
      font-weight: 600;
    }

    &-body {
      background: #f2f3f8;
      padding: 5px;
      border-radius: 5px;
      min-height: 100%;
    }
  }
}

.kanban-card {
  background: #fff;
  border-radius: 5px;
  padding: 5px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  & &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  & &__preview {
    width: 100px;
    height: 100px;
  }

  & &__title {
    width: 120px;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 5px;
    color: #646c9a;
    overflow-wrap: break-word;

    & > a {
      color: #646c9a;
    }
  }

  & &__parameter {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 600;
    }

    &-value {
      text-align: right;

      & > .badge {
        &.badge {
          &-warning {
            background-color: rgba(255, 184, 34, 0.9);
          }

          &-danger {
            background-color: rgba(253, 57, 122, 0.9);
          }
        }
      }
    }
  }
}

.kanban-filters {

  & &__view {
    width: 100%;
    height: 100%;
    transition: 0.1s ease all;
    position: relative;
    z-index: 2;
    visibility: visible;
    display: flex;
    align-items: center;
    padding: 0 25px;

    &-wrapper {
      display: flex;
      width: 100%;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      width: 100%;
      height: 22px;
    }
  }

  & &__sets {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.1s ease all;
    pointer-events: none;
    z-index: 1;
    height: calc(100vh - 139px);
    visibility: hidden;

    &-wrapper {
      border-radius: 0;
      box-shadow: none;
      max-height: 0;
      transition: 0.1s ease all;
      overflow: auto;
      pointer-events: all;
      transform: translateY(-50px);

      & > * {
        opacity: 0;
        transition: 0.1s ease all;
      }
    }
  }

  &.kanban-filters--hover,
  &:hover {
    & .kanban-filters {
      &__view {
        transform: translateY(200%);
        opacity: 0;
        visibility: hidden;
      }

      &__sets {
        background: rgba(0, 0, 0, 0.57);
        visibility: visible;

        &-wrapper {
          max-height: 100vh;
          transform: none;

          & > * {
            opacity: 1;
          }
        }
      }
    }
  }
}