$size: 120px;

.previewImg {
  overflow: hidden;
  width: $size;
  height: $size;
  position: relative;
  display: block;
  margin: 5px auto;
  border-radius: 4px;
  border: 1px solid #e2e5ec;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text{
    display: flex;
    width: $size;
    height: $size;
    flex-direction: column-reverse;
    text-align: center;
    font-size: 1.5rem;
  }

  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__wrapper {

    &-sm {
      width: 100%;
      padding: 0 10px;
    }

    &-sm__other {
      width: 100%;
      padding: 0 10px;
    }

    @media (min-width: 576px) {
      &-sm {
        width: $size + 20px;
      }

      &-sm__other {
        width: calc(100% - #{$size + 20px});
      }
    }
  }
}