.my-select--tags,
.my-select {
  border: none !important;
  height: auto;

  &__control {
    background: transparent !important;
    box-shadow: none !important;

    &--is-focused {
      border: 1px solid #2684ff !important;
    }
  }

  &__indicator {

  }

  .css-xb97g8:hover {
    background-color: #ffecf2;;
    color: #fd397a;
  }
}

.my-select--tags {
  height: auto;
  min-height: calc(1.5em + 1.3rem + 2px);
}

.my-select--tags__menu{
  z-index: 9999 !important;
}

.is-invalid {
  & .my-select {
    &__indicator {
      opacity: 0;
    }

    &__control {
      border: 1px solid #fd397a !important;
    }
  }
}
