
.print-only {
  display: none !important;
}

@media print {
  .no-print {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }

  .print-from-new-page {
    page-break-after: always !important;
  }
  .print-from-new-page:last-child {
    page-break-after: auto !important;
  }
}