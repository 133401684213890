//
// Page - Error 4
// Pages SASS files are compiled into separate css files
//



// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

.kt-error-v4 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    .kt-error_container {
        padding: 5% 0 0 5%;
        .kt-error_number {
            font-size: 9rem;
            font-weight: 600;
            color: #84D49E;
            line-height: 1;
        }

        .kt-error_title {
            font-size: 7rem;
            font-weight: 700;
            color: #84D49E;
            line-height: 1;
        }

        .kt-error_description {
            font-size: 2.5rem;
            font-weight: 600;
            color: #CC6622;
            line-height: 1;
        }
    }
}

@include kt-tablet {
    .kt-error-v4 {
        .kt-error_container {
            .kt-error_number {
                font-size: 9rem;
            }

            .kt-error_title {
                font-size: 7rem;
                font-weight: 600;
            }

            .kt-error_description {
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }
}

@include kt-mobile() {
    .kt-error-v4 {
        .kt-error_container {
            text-align: center;
            padding: 4rem 0 0 0;

            .kt-error_number {
                font-size: 8rem;
                font-weight: 600;
                line-height: 0.9;
            }

            .kt-error_title {
                text-align: center;
                font-size: 5rem;
                font-weight: 600;
                line-height: 0.9;
            }

            .kt-error_description {
                text-align: center;
                font-size: 2rem;
                line-height: 0.9;
            }
        }
    }
}
