.main-stat {
    background-color: #2c77f4;
    border-radius: 4px;
    padding: 1.2em;
    box-shadow: 1px 1px 5px #8d8c9b;
}

.main-stat p {
    margin-bottom: 0.1em;
    font-weight: 500;
    line-height: 1;
}

.main-stat.bg-primary {
    background-color: #2c77f4;
}
.main-stat.bg-danger {
    background-color: #fd397a;
}
.main-stat.bg-warning {
    background-color: #ffb822;
}

.text-xxl {
    font-size: 3.5em;
}

.text-md {
    font-size: 1.2em;
}