.my-tour {
  & .sc-bdVaJa {
    top: 12px;
    right: 12px;
  }

  &.my-tour {
    &--tasks {
      padding: 12px 15px;

      & .sc-bZQynM.jrBYqH {
        display: none;
      }
    }
  }

  &-btn {

    &-close {
      position: fixed;
      z-index: 99999999999;
      text-align: center;
      cursor: pointer;
      transition: 0.1s ease all;
      user-select: none;
      color: #fff;
      width: 25px;
      height: 25px;
      top: 5px;
      right: 5px;
      font-size: 2rem;
      line-height: 24px;
    }
  }
}