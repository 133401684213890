//
// Subheader
//


// Config
$kt-subheader-config: (
        fixed: (
                height: 50px
        ),
        toggle: (
                width: 20px,
                height: 22px,
                transition-duration: 0.4s,
                thickness: 2px,
                space: 4px,
                radius: 2px,
                color: (
                        default: kt-base-color(label, 2),
                        hover: kt-brand-color(),
                        active: kt-brand-color()
                )
        )
);

// Base
.kt-subheader {
  background-color: #fff;
  display: flex;
  align-items: stretch;
  margin: 10px 0;

  .kt-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }

  // Head actions buttons
  .btn {
    display: inline-flex;
    height: 32px !important;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;

    &.btn-sm {
      height: 24px !important;
    }

    i {
      font-size: 1rem;
    }

    &.btn-icon {
      width: 32px !important;
    }

    &:not(.dropdown-toggle-split) {
      margin-left: 0.5rem;
    }
  }

  // Main
  .kt-subheader__main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.25rem 0;

    // Buttons
    .btn {
      &:not(.dropdown-toggle-split) {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }

    // Mobile Toggler
    .kt-subheader__mobile-toggle {
      display: none;
    }

    // Title
    .kt-subheader__title {
      margin: 0;
      padding: 0 1rem 0 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #434349;
      display: flex;
      align-items: center;

      small {
        padding-left: 0.25rem;
        font-size: 0.9rem;
        color: kt-base-color(label, 2);
      }
    }

    // Desc
    .kt-subheader__desc {
      padding: 0 0.5rem 0 0;
      font-size: 1rem;
      font-weight: 500;
      color: #959cb6;
    }

    // Separator
    .kt-subheader__separator {
      display: block;
      width: 12px;
      height: 4px;
      border-radius: 2px;
      background: #e7e8ef;
      margin: 0 1rem 0 0;

      &.kt-subheader__separator--v {
        width: 1px;
        height: 22px;
      }
    }

    // Breadcrumbs
    .kt-subheader__breadcrumbs {
      display: flex;
      align-items: center;
      list-style-type: none;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;

      .kt-subheader__breadcrumbs-home {
        padding: 0px 0.65rem 0px 0px;
        @include kt-transition();

        > i {
          font-size: 1rem;
          color: #c5cbe7;
        }

        &:hover {
          > i {
            @include kt-transition();
            color: kt-brand-color();
          }
        }

        &.kt-subheader__breadcrumbs-home--active {
          > i {
            color: kt-brand-color();
          }
        }
      }

      .kt-subheader__breadcrumbs-link {
        padding: 0 0.35rem 0 0;
        font-size: 1rem;
        font-weight: 500;
        color: #959cb6;

        &:hover {
          color: kt-brand-color();
        }

        &.kt-subheader__breadcrumbs-link--active {
          color: kt-brand-color();
        }

        @include kt-hover-transition();
      }

      .kt-subheader__breadcrumbs-separator {
        display: flex;
        align-items: center;
        padding: 0 0.35rem 0 0;

        &:after {
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          content: " ";
          background: #e7e8ef;
        }
      }
    }

    // Group
    .kt-subheader__group {
      display: flex;
      align-items: center;
    }
  }

  // Toolbar
  .kt-subheader__toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;

    // Toolbar buttons
    .btn:not(.dropdown-toggle-split) {
      margin-left: 0.25rem;
    }
  }

  // Primary button
  .kt-subheader__btn-primary {
    background: #f2f3f7;
    color: #959cb6;
    font-weight: 500;

    i {
      color: #959cb6;
    }

    &:hover,
    &.active,
    &:active,
    &:focus {
      color: kt-brand-color(inverse);
      background: kt-brand-color();

      i {
        color: kt-brand-color(inverse);
      }

      @include kt-svg-icon-color(kt-brand-color(inverse));
    }
  }

  // Secondary button
  .kt-subheader__btn-secondary {
    color: #959cb6;
    font-weight: 500;

    i {
      color: #aab1df;
    }

    @include kt-svg-icon-color(#aab1df);

    &:hover,
    &.active,
    &:active,
    &:focus {
      background: #f2f3f7;
      color: kt-brand-color();
      @include kt-svg-icon-color(kt-brand-color());
    }
  }

  // Daterange
  .kt-subheader__btn-daterange {
    background: #f2f3f7;

    .kt-subheader__btn-daterange-title {
      display: inline-block;
      color: #959cb6;
      font-weight: 500;
    }

    .kt-subheader__btn-daterange-date {
      display: inline-block;
      color: kt-brand-color();
      margin-right: 0.75rem;
      font-weight: 500;
    }

    i {
      color: kt-brand-color();
    }


    &:hover,
    &.active,
    &:active,
    &:focus {
      background: #f2f3f7;

      i {
        color: kt-brand-color();
      }
    }
  }

  // Search
  .kt-subheader__search {
    width: 175px;
    display: inline-block;

    .form-control {
      font-size: 0.9rem;
      height: 33px;
      background: #f2f3f7;
      border-color: #f2f3f7 !important;
    }

    .kt-input-icon__icon {
      i {
        font-size: 1.2rem;
        color: kt-base-color(label, 2);
      }
    }
  }

  // Solid style
  .kt-subheader--solid & {
    margin: 0 0 kt-get($kt-page-padding, desktop) 0;
    min-height: kt-get($kt-subheader-config, fixed, height);
  }

  // Transparent style
  .kt-subheader--transparent & {
    background-color: transparent;

    // Main
    .kt-subheader__main {
      .kt-subheader__separator {
        background: darken(#e7e8ef, 4%);
      }

      .kt-subheader__breadcrumbs {
        .kt-subheader__breadcrumbs-home {
          > i {
            color: darken(#c5cbe7, 4%);
          }
        }

        .kt-subheader__breadcrumbs-link {
          color: #959cb6;
        }

        .kt-subheader__breadcrumbs-separator {
          &:after {
            background: darken(#e7e8ef, 4%);
          }
        }
      }
    }

    // Primary button
    .kt-subheader__btn-primary {
      background: darken(#eaecf2, 3%);
      color: #959cb6;

      i {
        color: #959cb6;
      }

      @include kt-svg-icon-color(#959cb6);

      &:hover,
      &.active,
      &:active,
      &:focus {
        background: kt-brand-color();
        color: kt-brand-color(inverse);

        i {
          color: kt-brand-color(inverse);
        }

        @include kt-svg-icon-color(kt-brand-color(inverse));
      }
    }

    // Secondary button
    .kt-subheader__btn-secondary {
      color: darken(#959cb6, 3%);

      i {
        color: darken(#aab1df, 3%);
      }

      &:hover,
      &.active,
      &:active,
      &:focus {
        background: #eaecf2;
      }
    }

    // Daterange
    .kt-subheader__btn-daterange {
      background: darken(#eaecf2, 3%);

      &:hover,
      &.active,
      &:active,
      &:focus {
        background: darken(#eaecf2, 6%);
      }
    }

    // Search
    .kt-subheader__search {
      .form-control {
        background: #eaecf2;
        border-color: #eaecf2 !important;
      }
    }
  }

  &.kt-subheader {
    &--sm {
      .btn {
        font-size: 0.8rem !important;
        padding: 0.5rem 0.8rem;

        i {
          font-size: 0.8rem;
        }

        &.btn-sm {
          padding: 0.3rem 0.6rem;
        }
      }


      .kt-subheader__main {
        .kt-subheader__title {
          font-size: 0.9rem;

          small {
            font-size: 0.7rem;
          }
        }

        // Desc
        .kt-subheader__desc {
          font-size: 0.8rem;
        }

        // Breadcrumbs
        .kt-subheader__breadcrumbs {

          .kt-subheader__breadcrumbs-home {

            > i {
              font-size: 0.8rem;
            }
          }

          .kt-subheader__breadcrumbs-link {
            font-size: 0.8rem;
          }
        }
      }

      & .form-group {
        select,
        input,
        label {
          font-size: 0.8rem;
        }
      }
    }
  }
}

@include kt-tablet-and-mobile {
  // Subheader
  .kt-subheader {
    margin: 2px 0;

    // Main
    .kt-subheader__main {
      // Mobile Toggler
      @include kt-burger-icon-build(kt-subheader__mobile-toggle, kt-get($kt-subheader-config, toggle));
      @include kt-burger-icon-skin(kt-subheader__mobile-toggle, kt-get($kt-subheader-config, toggle, color));

      .kt-subheader__mobile-toggle {
        display: inline-block;
        margin-right: 1rem;
      }

      // Title
      .kt-subheader__title {
        padding: 0.5rem 1.25rem 0.5rem 0;
      }

      // Breadcrumbs
      .kt-subheader__breadcrumbs {
        padding: 0.5rem 0;
      }
    }

    // Toolbar
    .kt-subheader__toolbar {
      padding: 0.5rem 0;
    }

    // Light style
    .kt-subheader--transparent & {
      background-color: transparent;
    }
  }
}

@include kt-mobile {
  // Subheader
  .kt-subheader {

    .kt-subheader__main {
      max-width: calc(100% - 55px);
      height: 50px;
    }

    // Toolbar
    .kt-subheader__toolbar {
      width: 40px;
      height: 50px;

      & > .btn {
        &:not(.dropdown-toggle-split) {
          margin-left: 0;
        }

        text-align: center;
        justify-content: center;
        margin: 0;
        width: 100%;
      }
    }
  }
}
