
.document {
  & img {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &.ql-editor > * {
    cursor: default;
  }
}

.ql-editor {
  min-height: 160px;
}