//
// User Profile 1
//



.kt-widget {
    &.kt-widget--user-profile-1 {
        padding-bottom: 1.7rem;

        .kt-widget__head {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .kt-widget__media {
                img {
                    object-fit: cover;
                    height: 90px;
                    width: 90px;
                    max-width: 100%;

                    @include kt-rounded {
                        border-radius: 8px;
                    }
                }
            }

            .kt-widget__content {
                padding-left: 1.6rem;

                .kt-widget__section {
                    .kt-widget__username {
                        font-size: 1.3rem;
                        color: kt-base-color(label, 4);
                        font-weight: 500;

                        &:not(.no-hover):hover {
                            color: kt-brand-color();
                            transition: color 0.3s ease;
                        }

                        i {
                            font-size: 1.1rem;
                            padding-left: 0.4rem;
                        }
                    }

                    .kt-widget__subtitle {
                        font-size: 1;
                        display: block;
                        padding: 0.25rem 0 0 0;
                        font-weight: 500;
                        color: kt-base-color(label, 2);
                    }
                }

                .kt-widget__action {
                    margin-top: 1rem;

                    .btn {
                        margin-right: 0.4rem;
                        font-weight: 600;
                        padding: 0.3rem 1rem;
                    }
                }
            }
        }

        .kt-widget__body {
            .kt-widget__content {
                padding: 1.9rem 0 2.1rem 0;

                .kt-widget__info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 0.5rem;

                    .kt-widget__label {
                        color: kt-base-color(label, 4);
                        font-weight: 500;
                    }

                    .kt-widget__data {
                        color: kt-base-color(label, 2);
                        font-weight: 400;
                    }

                    a.kt-widget__data:hover {
                        color: kt-brand-color();
                        transition: color 0.3s ease;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }

            .kt-widget__items {
                .kt-widget__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.9rem 1.05rem 0.9rem 0.6rem;
                    margin: 0.4rem 0;

                    &.kt-widget__item--active {
                        transition: color 0.3s ease;
                        background: #f2f3f7;

                        @include kt-rounded {
                            border-radius: $kt-border-radius;
                        }

                        .kt-widget__section {
                            .kt-widget__desc {
                                color: kt-brand-color();
                            }

                            .kt-widget__icon {
                                .kt-svg-icon g [fill] {
                                    fill: kt-brand-color();
                                }
                            }
                        }
                    }

                    .kt-widget__section {
                        .kt-widget__icon {
                            svg {
                                width: 2rem;
                                height: 1.5rem;
                            }

                            .kt-svg-icon g [fill] {
                                fill: kt-base-color(label, 2);
                            }
                        }

                        .kt-widget__desc {
                            color: kt-base-color(label, 2);
                            font-weight: 500;
                            padding-left: 0.3rem;
                        }
                    }

                    &:hover {
                        transition: color 0.3s ease;
                        background: #f2f3f7;
                        cursor: pointer;

                        @include kt-rounded {
                            border-radius: $kt-border-radius;
                        }

                        .kt-widget__desc {
                            color: kt-brand-color();
                        }

                        .kt-widget__icon {
                            .kt-svg-icon g [fill] {
                                fill: kt-brand-color();
                            }
                        }
                    }
                }
            }
        }
    }

    .kt-portlet__body.kt-portlet__body--fit-y & {
        margin-top: -10px;
    }
}


@include kt-tablet-and-mobile {
    .kt-widget {
        &.kt-widget--user-profile-1 {
            padding-bottom: 1.2rem;

            .kt-widget__head {
                .kt-widget__media {
                    img {
                        object-fit: cover;
                        height: 60px;
                        max-width: 60px;
                    }
                }

                .kt-widget__content {
                    .kt-widget__section {
                        .kt-widget__username {
                            font-size: 1.1rem;
                            color: kt-base-color(label, 4);
                            font-weight: 500;
                        }
                    }

                    .kt-widget__action {
                        margin-top: 0.5rem;

                        .btn {
                            margin-right: 0.4rem;
                            font-weight: 500;
                            padding: 0.25rem 1.25rem;
                        }
                    }
                }
            }

            .kt-widget__body {
                .kt-widget__content {
                    padding-bottom: 2rem;
                }
            }
        }
    }
}
