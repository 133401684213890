
.project {
  &.project {
    &--select {
      box-shadow: 0 0 0 2px rgba(44, 119, 244, 0.5) inset;
    }

    &--table {
      &.project {
        &--select {
          & > td,
          & > th {
            border-top-color: transparent;
          }
        }
      }
    }
  }
}