.css-yk16xz-control {
  border: 1px solid #e2e5ec !important;
  border-radius: 4px !important;
}

.css-1pahdxg-control {
  color: #495057 !important;
  background-color: #fff !important;
  border: 1px solid #e2e5ec !important;
}
