.stat-row td {
  border-top: none;
  padding: .35rem;
}

.justify-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.time-tracker-input {
  min-width: 30px;
  max-width: 90px;
}

.ellipsis-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fixed-layout-table {
  table-layout: fixed;
}

.bg-transparent {
  background-color: transparent;
}

.kt-demo-panel--right .kt-demo-panel {
  right: -570px;
}

.kt-demo-panel {
  width: 550px!important;
}

.projects-filter.kt-demo-panel {
  width: 90%!important;
}

.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon.kt-pulse i {
  color: #c7c7de;
  animation: kt-pulse-color 2.5s ease-out infinite;
}

@keyframes kt-pulse-color {
  0% {color: #c7c7de;}
  50% {color: #2c77f4;}
  100% {color: #c7c7de;}
}

.kt-widget__ellipsis {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.my-select--is-disabled {
  background-color: #f7f8fa;
}

.form-control:disabled {
  background-color: #f7f8fa!important;
}

.table-stat {
  thead th, thead tr {
    position: inherit!important;
  }
}

.opacity-0 {
  opacity: 0;
}

.kt-login__footer {
  position: absolute;
  bottom: 2em;
  left: 0;
  width: 100%;
  text-align: center;
}

.z-index-0 {
  z-index: 0;
}

.editors-statistics.table-striped tbody tr:nth-of-type(odd) {
  background-color: #e5fdff;
}
.editors-statistics.table-striped tbody tr:nth-of-type(even) {
  background-color: #cde1ff;
}
.editors-statistics.table-striped tbody tr:nth-of-type(odd) td:first-child,
.editors-statistics.table-striped tbody tr:nth-of-type(odd) td:nth-child(2) {
  background-color: #e5fdff;
}
.editors-statistics.table-striped tbody td:first-child p {
  width: 150px;
}
.editors-statistics.table-striped tbody tr:nth-of-type(even) td:first-child,
.editors-statistics.table-striped tbody tr:nth-of-type(even) td:nth-child(2) {
  background-color: #cde1ff;
}
.editors-statistics.table-striped td:nth-of-type(odd) {
  background-color: #d9d3fa;
}
.editors-statistics.table-striped th:first-child {
  background-color: #ffffff!important;
}
.editors-statistics {
  .bg-secondary {
    td, td:nth-child(2) div {
      background-color: #7aadfa;
    }
    td:nth-of-type(odd) {
      background-color: #d9d3fa;
    }
    td:first-child {
      background-color: #7aadfa!important;
    }
  }
 td:first-child, th:first-child {
    position: sticky;
    left: 0;
    z-index: 1!important;
  }
  td:nth-child(2) {
    .report-category-odd {
      background-color: #b9e1ff!important;
    }
    .report-category-even {
      background-color: #aaccff!important;
    }
  }
  td:nth-of-type(odd) {
    .report-category-odd,
    .report-category-even {
      background-color: #d9d3fa!important;
    }
  }
  th:nth-of-type(odd) {
    background-color: #d9d3fa!important;
  }
  th:nth-child(2), td:nth-child(2) {
    position: sticky;
    left: 160px;
    z-index: 1!important;
    border-right: 1px solid white;
    box-sizing: border-box;
  }
  .report-category-odd {
    background-color: #b9e1ff;
  }
  .report-category-even {
    background-color: #aaccff;
  }
  thead tr{
    top: 0!important;
    z-index: 2!important;
    position: sticky!important;
  }
  td {
    z-index: 0;
    padding: 0;
    border: 0;
    div{
      border: 1px solid #ffffff;
      font-weight: 500;
    }
  }
  .category-row td {
    vertical-align: top!important;
  }
  .image-types {
    margin: 0;
    font-weight: 400;
  }
  .image-types div{
    font-weight: 400!important;
  }
}

.test-settings {
  position: fixed;
  right: 60px;
  bottom: 60px;
  z-index: 9999999;
  i {
    padding: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(#95de64, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(#95de64, 0);
  }
}

.progress-btn-circle {
  border-color: #95de64 !important;
  background: #95de64 !important;
  -webkit-animation: pulse 1.5s infinite;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(#95de64, .5);
}

.progress-btn-circle:hover {
  text-decoration: none;
  border-color: #73d13d !important;
  background: #73d13d !important;
  color: black;
  -webkit-animation: none;
}

.progress-btn-circle[disabled] {
  color: #b2bec3;
  border-color: #dfe6e9 !important;
  background: #f7fafc !important;
  text-shadow: none;
  box-shadow: none;
}

.warning-btn-circle {
  border-color: rgba(255, 194, 18, 0.96) !important;
  background: rgba(255, 194, 18, 0.96) !important;
  border-radius: 50%;
}

.warning-btn-circle:hover {
  text-decoration: none;
  border-color: #fdbc02 !important;
  background: #fdbc02 !important;
  color: black;
}

.warning-btn-circle[disabled] {
  color: #b2bec3;
  border-color: #dfe6e9 !important;
  background: #f7fafc !important;
  text-shadow: none;
  box-shadow: none;
}

#kt_footer .modal-body {
  overflow: visible;
}

.groups-list .btn-danger.disabled, .btn-danger:disabled {
  background-color: #bf486f;
  border-color: #bf486f;
  cursor: not-allowed!important;
}

.groups-list .my-select {
  z-index: 2;
}

.custom-table .table thead tr {
  z-index: 1!important;
}

.tag-picture-preview {
  width: 250px;
  display: block;
}

@media screen and (min-width: 1920px) {
  .tag-picture-preview {
    width: 350px;
  }
}

.slider-container {
  width: 100%;
  min-height: 100px;
}

.tags-match-wrapper {
  top: 5px;
  left: 5px;
  border: 1px solid white;
}

.tags-match__item {
  padding: 2px 5px;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.tags-match__item:not(:last-child) {
  border-right: 1px solid white;
}

.font-weight-semibold {
  font-weight: 600;
}

.subtitle-separator {
  display: block;
  width: 1px;
  height: 22px;
  border-radius: 2px;
  background: #e7e8ef;
  margin: 0 1rem 0 .5rem;
}

.template-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 24px; /* Размер шрифта */
  color: #48465b;  /* Цвет текста */
}

.template-title::before,
.template-title::after {
  content: "";
  height: 1px;
  background-color: #d3d3d3; /* Цвет линий */
}

.template-title::before {
  flex: 0 0 10%; /* Левая линия занимает 30% */
  margin-right: 10px; /* Отступ между линией и текстом */
}

.template-title::after {
  flex: 1; /* Правая линия занимает оставшееся пространство (70%) */
  margin-left: 10px; /* Отступ между линией и текстом */
}

.confirm-child-tag {
  .modal-body {
    overflow: visible;
  }
}

.is-invalid:has(~ .title-unique-validation) {
  border-width: 3px;
}
